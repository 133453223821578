﻿.gallery {
	@extend .no-bullets;
	list-style: none;
	overflow: hidden;
	margin: 0 -($grid-column-gutter / 2);
	width: calc(100% + 50px);

	li {

		@include bp-min(s) {
			float: left;
			width: 50%;
		}

		@include bp-min(m) {
			width: (100% / 3);
		}
	}
}

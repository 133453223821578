﻿$icon-arrow-bold-down: "\ea01";
$icon-arrow-bold-left: "\ea02";
$icon-arrow-bold-right: "\ea03";
$icon-arrow-bold-up: "\ea04";
$icon-arrow-bottom: "\ea05";
$icon-arrow-left: "\ea06";
$icon-arrow-right: "\ea07";
$icon-arrow-up: "\ea08";
$icon-bus: "\ea09";
$icon-calendar: "\ea0a";
$icon-car: "\ea0b";
$icon-close: "\ea0c";
$icon-contract: "\ea0d";
$icon-cv: "\ea0e";
$icon-green-talent: "\ea0f";
$icon-home: "\ea10";
$icon-house: "\ea11";
$icon-insurance: "\ea12";
$icon-mail: "\ea13";
$icon-play: "\ea14";
$icon-print: "\ea15";
$icon-question: "\ea16";
$icon-salary: "\ea17";
$icon-telephone: "\ea18";
$icon-telephone-outline: "\ea19";
$icon-tv: "\ea1a";
$icon-upload: "\ea1b";
$icon-whatsapp: "\ea1c";

@at-root {
    @font-face {
        font-family: icon;
        
        src: url("../../fonts/icon.woff2") format("woff2"), url("../../fonts/icon.woff") format("woff"); 
        font-style: normal;
        font-weight: normal;
    }
}

@mixin icon($content: none) {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-family: icon;
    line-height: 1;
    text-transform: none;
    speak: none;
    content: $content;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon:before {
    @include icon;
}

.icon-arrow-bold-down:before { content: $icon-arrow-bold-down; }
.icon-arrow-bold-left:before { content: $icon-arrow-bold-left; }
.icon-arrow-bold-right:before { content: $icon-arrow-bold-right; }
.icon-arrow-bold-up:before { content: $icon-arrow-bold-up; }
.icon-arrow-bottom:before { content: $icon-arrow-bottom; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-arrow-up:before { content: $icon-arrow-up; }
.icon-bus:before { content: $icon-bus; }
.icon-calendar:before { content: $icon-calendar; }
.icon-car:before { content: $icon-car; }
.icon-close:before { content: $icon-close; }
.icon-contract:before { content: $icon-contract; }
.icon-cv:before { content: $icon-cv; }
.icon-green-talent:before { content: $icon-green-talent; }
.icon-home:before { content: $icon-home; }
.icon-house:before { content: $icon-house; }
.icon-insurance:before { content: $icon-insurance; }
.icon-mail:before { content: $icon-mail; }
.icon-play:before { content: $icon-play; }
.icon-print:before { content: $icon-print; }
.icon-question:before { content: $icon-question; }
.icon-salary:before { content: $icon-salary; }
.icon-telephone:before { content: $icon-telephone; }
.icon-telephone-outline:before { content: $icon-telephone-outline; }
.icon-tv:before { content: $icon-tv; }
.icon-upload:before { content: $icon-upload; }
.icon-whatsapp:before { content: $icon-whatsapp; }

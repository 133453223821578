﻿.testimonial {
	margin-bottom: rem(20px);

	.content {
		background: $color-contrast;
		font-size: rem(18px);
		padding: rem(20px);
		border-radius: rem(5px);
		margin-bottom: rem(20px);

		@include bp-min(m) {
			padding: rem(40px) rem(80px);
		}

		.quote {
			font-size: rem(24px);
			line-height: rem(40px);
			margin: 0;

			p {
				margin: 0 0 rem(30px);
			}
		}
	}
}

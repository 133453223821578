﻿.image {
	width: 100%;
	height: auto;

	&.-fill-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;

		&.-contain {
			object-fit: contain;
		}
	}

	@include bp-min(m) {
		&.-fill-container-m {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;

			&.-contain {
				object-fit: contain;
			}
		}
	}

	&.-small {
		max-width: rem(60px);
	}

	&.-round {
		border-radius: 100%;
	}

	&.is-video {
		position: relative;
		margin: 0;

		&:before {
			position: absolute;
			content: '';
			z-index: 1;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: rgba(#000, 0.8);
		}


		&:after {
			@include icon($icon-play);
			position: absolute;
			z-index: 2;
			width: rem(80px);
			height: rem(80px);
			border-radius: 50%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: #fff;
			color: $color-primary;
			text-align: center;
			line-height: rem(80px);
			font-size: rem(32px);
			padding: 0 0 0 rem(6px);
			transition: transform 0.3s ease;

			@include bp-min(m) {
				width: rem(160px);
				height: rem(160px);
				line-height: rem(160px);
				font-size: rem(50px);
				padding: 0 0 0 rem(12px);
			}
		}

		&:hover {

			&:after {
				transform: translate(-50%, -50%) scale(1.1);
			}
		}
	}
}

.image-container {
	@include responsive-container-psudo(600, 450);
	position: relative;
	margin: 0 0 rem(40px);
	width: 100%;

	@include bp-min(m) {
		float: right;
		max-width: rem(600px);
		margin: rem(6px) 0 rem(40px) rem(130px);
	}

	&.-col {
		margin: 0;
		min-height: rem(400px);
	}

	&.-slide {
		margin: 0;
		max-width: initial;

		@include bp-min(m) {
			width: calc(100% - 32.5rem);
			float: left;
		}
	}

	&.-gallery-big,
	&.-gallery {
		@include responsive-container-psudo(470, 313);
		box-sizing: border-box;
		border: ($grid-column-gutter / 4) solid transparent;
		margin: 0;

		@include bp-min(s) {
			height: 50%;
			border: ($grid-column-gutter / 2) solid transparent;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&.-gallery-big {
		@include responsive-container-psudo(470, 676);

		@include bp-min(s) {
			height: 100%;
		}

		img {
			height: 100%;
		}
	}

	.person {
		margin: 0;

		figcaption {
			position: relative;
			z-index: 10;

			p {
				margin: 0;

				strong {
					font-size: 1.25rem;
				}
			}

			@include bp-min(m) {
				position: absolute;
				top: 5rem;
				left: 0;
			}

			@include bp-min(xl) {
				top: 50%;
				left: 2.5rem;
				transform: translateY(-50%);
			}
		}
	}
}

﻿.content-block {
	@extend .clearfix;
	position: relative;

	@include bp-min(m) {
		display: flex;
		flex-wrap: wrap;
	}

	a {
		text-decoration: underline;
	}

	.col {
		position: relative;
	}

	.col.-form,
	&.-item {
		padding: rem(99px) 0 rem(100px);
	}

	&:nth-of-type(even) {
		background: $color-contrast;

		.overview-item {
			background: #fff;
		}
	}

	&.-primary-dark {
		background: $color-primary-dark;
		color: #fff;
	}

	&.-alt-background {
		padding: rem(99px) 0 rem(100px);
		background: $color-contrast;
		overflow: hidden;

		.frontpage & {
			background: $color-contrast url(../../images/faq-background.png) bottom right no-repeat;

			&:after {
				background: $color-primary;
				content: '';
				width: rem(150px);
				height: rem(150px);
				position: absolute;
				top: 0;
				left: rem(-75px);
				border-radius: rem(25px);
				transform: rotate(45deg) translateY(-150px);

				@include bp-min(xl) {
					width: rem(200px);
					height: rem(200px);
					left: rem(-100px);
					border-radius: rem(33px);
					transform: rotate(45deg) translateY(-200px);
				}

				@include bp-min(xxl) {
					width: rem(300px);
					height: rem(300px);
					left: rem(-150px);
					border-radius: rem(50px);
					transform: rotate(45deg) translateY(-300px);
				}
			}
		}

		body:not(.frontpage) &:nth-of-type(odd) {
			background-color: #fff;
		}
	}

	&.-green {
		background: $color-primary;
	}

	.-reversed {
		flex-flow: row-reverse;
	}

	&.-overview {
		padding: 100px 0;
	}

	&.-vacancy-intro {

		.image {
			margin-bottom: rem(32px);
		}

		.link.-back-to-overview {
			display: inline-block;
			margin-top: rem(48px);
		}

		.button {
			margin-bottom: rem(24px);
		}

		.block-row-m-2.has-links {
			margin-top: rem(24px);
			margin-bottom: rem(48px);
		}

		.share {
			margin-bottom: rem(48px);
		}
	}

	&.-contact-block {

		.content-block__content {
			max-width: rem(755px);
			padding: 0 rem(20px);

			@include bp-min(m) {
				padding: 0 0 0 rem(25px);
				margin-left: auto;
			}
		}

		figure {
			position: relative;
			min-height: rem(400px);
			height: 100%;
			margin: 0;
			overflow: hidden;

			img {
				position: absolute;
				z-index: 0;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				object-fit: cover;
				object-position: center;
			}

			&:before {
				@include bp-min(m) {
					position: absolute;
					z-index: 1;
					content: '';
					width: 100%;
					min-height: rem(995px);
					height: 100%;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					background: url(../../images/contact-block-background.png) no-repeat left center;
					background-size: contain;
				}
			}
		}
	}
}

.text-content {
	padding: rem(83px) 0 rem(63px);

}

.image-slider.is-small {
	padding-bottom: rem(50px);

	.block-row-m-2 {

		> div {
			margin-bottom: rem(50px);
		}
	}

	img {
		width: 100%;
	}
}

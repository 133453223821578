﻿.hero {
	background: $color-primary;
	color: #fff;
	position: relative;
	z-index: 2;

	.hero-image {
		position: relative;

		@include bp-min(m) {
			&:after {
				position: absolute;
				top: 0;
				right: 0;
				z-index: 3;
				content: '';
				background: linear-gradient(to left, $color-primary 10%,rgba(148,183,187,0) 100%);
				width: 50%;
				height: 100%;
			}
		}
	}

	.hero-content {
		margin: 0 auto;
		font-size: rem(18px);
		font-weight: 600;
		position: relative;

		&:before {
			@include bp-min(m) {
				@include icon($icon-green-talent);
				color: rgba(255,255,255,0.1);
				font-size: rem(100px);
				position: absolute;
				right: rem(40px);
				bottom: rem(40px);
			}

			@include bp-min(xxl) {
				font-size: rem(420px);
				top: 50%;
				bottom: auto;
				transform: translateY(-50%);
			}
		}

		.container {
			padding-top: rem(20px);
			padding-bottom: rem(20px);

			@include bp-min(m) {
				max-width: rem(500px);
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				margin: 0;
			}

			@include bp-min(xxl) {
				min-width: rem(500px);
				max-width: 50%;
			}
		}

		.buttons {
			display: flex;
			flex-wrap: wrap;
			grid-gap: rem(10px) rem(10px);
		}

		.button {
			display: flex;
			align-items: initial;
			width: max-content;
		}
	}
}

﻿.languages {
	color: $color-primary-dark;
	font-weight: bold;
	font-size: rem(24px);
	font-family: $base-font-title;

	@include bp-min(l) {
		color: $color-primary;
		font-size: rem(18px);
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;

		&:hover {
			dd {
				display: block;
			}
		}
	}

	dt {
		display: none;

		@include bp-min(l) {
			background: $color-contrast;
			display: block;
			padding: rem(25px);
			text-align: right;

			&:after {
				@include icon($icon-arrow-bottom);
				font-size: rem(10px);
				margin-left: rem(6px);
			}
		}
	}

	dd {
		@include bp-min(l) {
			display: none;
			background: $color-contrast;
			position: absolute;
		}

		ul {
			padding: rem(10px);
			margin: 0;

			li {
				display: inline-block;

				a {
					display: block;
					padding: rem(5px) rem(10px);
					transition: color 250ms ease-in-out;
				}

				&:hover > a,
				&.active > a {
					color: #fff;

					@include bp-min(l) {
						color: $color-primary-dark;
					}
				}
			}
		}
	}
}
﻿.steps {
	.step {
		padding: 1rem;

		a {
			text-decoration: underline;
		}

		.block {
			position: relative;
			margin-bottom: rem(20px);

			&:before,
			&:after {
				@include icon($icon-arrow-right);
				color: mix(#fff, $color-primary, 50%);
				font-size: rem(32px);
				position: absolute;
				top: 50%;
				right: rem(-52px);
				transform: translateY(-50%);
			}

			&:after {
				right: rem(-62px);
			}
		}

		&:last-of-type {
			.block {
				&:before,
				&:after {
					display: none;
				}
			}
		}

		.step-text {
			text-align: left;
		}

		.icon {
			@include responsive-container-psudo(1,1);
			color: $color-primary;
			font-size: rem(90px);
			background: #fff;
			display: inline-block;
			width: 100%;
			max-width: rem(210px);
			border: rem(10px) solid mix(#fff, $color-primary, 90%);
			border-radius: 100%;

			&:before {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.-bullet {
			background: #fff;
			display: inline-block;
			width: rem(36px);
			height: rem(36px);
			border-radius: 100%;
			margin-bottom: rem(20px);
			font-weight: bold;
			color: $color-primary;
			font-size: rem(18px);
			text-align: center;
			line-height: rem(36px);
		}
	}
}

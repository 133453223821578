﻿.share {
	display: flex;
	align-items: center;
	background: #f5f5f5;
	padding: rem(9px) rem(32px);
	margin-top: rem(30px);

	.share__title {
		font-size: rem(16px);
		line-height: rem(36px);
	}

	.share__item {
		position: relative;
		width: rem(32px);
		height: rem(32px);
		text-indent: -99999px;
		overflow: hidden;
		margin: 0 rem(21px);
		transition: transform 0.3s ease-in-out;

		&:hover {
			transform: translateY(-4px);
		}

		&.-facebook {
			background: url(../../images/facebook.svg) no-repeat center;
		}

		&.-whats-app {
			background: url(../../images/whatsapp.svg) no-repeat center;
		}

		&.-mail {

			&:before {
				@include icon($icon-mail);
				position: absolute;
				font-size: rem(32px);
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-indent: 0;
				color: $color-primary;
			}
		}
	}

	.modal & {
		margin: rem(60px) rem(-60px) rem(-23px);
	}
}

﻿.title {
	@include font-title-40;
	font-weight: bold;
	margin: 0 0 rem(30px);

	&.-center {
		text-align: center;
	}

	&.-medium {
		@include font-title-32;
		margin: 0 0 rem(25px);
	}

	&.-small {
		@include font-title-24;
		font-weight: normal;
		margin: 0 0 rem(21px);
	}

	&.-primary {
		color: $color-primary;
	}
}

.title-group {
	margin: 0 0 rem(30px);

	h2 {
		margin: 0;
	}

	span {
		font-weight: normal;
	}
}

.title-header {
	padding: rem(80px) 0;
	background: $color-primary;
	color: #fff;
}
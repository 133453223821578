﻿.fileupload {

	input {
		width: 0.1px !important;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	input + .fileupload-label {
		position: relative;
		background: #fff;
		width: 100% !important;
		height: rem(50px);
		padding: rem(10px) rem(20px);
		margin-bottom: rem(10px);
		color: $color-primary-dark;
		font-weight: bold;

		@include bp-min(m) {
			//display: flex;
			width: calc(100% - 13.125rem) !important;
		}

		span {
			margin: 0 rem(5px);
		}

		&:after {
			position: absolute;
			content: attr(data-caption);
			background: #fff;
			color: $color-primary-dark;
			width: 100%;
			height: rem(50px);
			padding: 0.625rem 40px 0.625rem 1.25rem;
			top: 0;
			right: 0;
			transition: all 0.3s ease-in-out;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&:before {
			@include icon($icon-upload);
			position: absolute;
			z-index: 1;
			color: $color-primary-dark;
			font-size: rem(19px);
			right: rem(17px);
			bottom: rem(16px);
		}
	}

	input:focus + label,
	input + label:hover {

		&:after {
			//background: lighten(color('secondary'), 30%);
			//color: $white;
		}
	}
}

﻿.button {
	@include font-title-18;
	cursor: pointer;
	display: inline-block;
	position: relative;
	z-index: 0;
	color: #fff;
	font-weight: bold;
	font-style: initial;
	vertical-align: middle;
	text-decoration: none !important;
	letter-spacing: 0;
	min-height: 0; // Using line-height instead
	padding: rem(12px) rem(30px);
	border: none !important;
	overflow: hidden;
	background: $color-primary;
	transition: all 0.3s ease-out;
	transform: perspective(1px) translateZ(0); // Improve performance on mobile/tablet devices // Perspective reduces blurryness of text in Chrome
	box-shadow: 0 0 1px rgba(0, 0, 0, 0); // Improve aliasing on mobile/tablet devices
	&:hover,
	&:focus,
	&:active,
	&.hover {
		background: darken($color-primary, 10%);

		&:after {
			color: $color-primary;
		}
	}

	&.-big {
		padding-top: rem(17px);
		padding-bottom: rem(17px);

		.icon {
			font-size: rem(23px);
			line-height: rem(23px);
			height: 24px;
			overflow: hidden;
			vertical-align: inherit;
			margin-right: rem(10px);
		}
	}

	&.-banana {
		background: $color-banana;
		color: #fff;

		&:hover,
		&:focus,
		&:active,
		&.hover {
			background: #fff;
			color: $color-banana;
		}

		&.-alt {
			background: #fff;
			color: $color-banana;

			&:hover,
			&:focus,
			&:active,
			&.hover {
				background: $color-banana;
				color: #fff;
			}
		}
	}

	&.-white {
		background: #fff;
		color: $color-primary;

		&:hover,
		&:focus,
		&:active,
		&.hover {
			background: darken($color-primary, 10%);
			color: #fff;
		}
	}

	&.is-print {

		&:before {
			@include icon($icon-print);
			position: relative;
			top: rem(3px);
			right: rem(10px);
		}
	}

	&.is-full-width {
		width: 100%;
		text-align: center;
		cursor: pointer;
	}

	&.is-big {
		@include font-title-20;
		padding-top: 21px;
		padding-bottom: 21px;
	}
}

.buttons {
	margin: rem(-10px);

	.button {
		margin: rem(10px);
	}

	&:last-child {
		margin-bottom: rem(30px);
	}
}
﻿.video {
	position: relative;
	display: block;

	.icon {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 150%;
		vertical-align: middle;
		color: $color-primary;
		background: #fff;
		width: 4rem;
		height: 4rem;
		padding: 1.25rem 0 0 0.375rem;
		margin: -2rem 0 0 -2rem;
		border-radius: 100%;
		overflow: hidden;
		transition: color 0.2s ease-out;

		@include bp-min(m) {
			font-size: 250%;
			width: 7.5rem;
			height: 7.5rem;
			padding: 2.5rem 0 0 0.375rem;
			margin: -3.75rem 0 0 -3.75rem;
		}

		&:before {
			position: relative;
			z-index: 2;
		}
		// Radial out
		&:after {
			border-radius: 100%;
			transform: scale(0);
			transform-origin: center;
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $color-primary;
			transition: transform 0.2s ease-out;
		}
	}

	&:hover,
	&:focus {
		.icon {
			color: #fff;

			&:after {
				transform: scale(2);
			}
		}
	}
}

.video-wrapper {
	position: relative;


	&:before {
		position: relative;
		display: block;
		content: "";
		padding-top: (100% / 730 * 530);
	}

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}
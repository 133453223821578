﻿.modal-container {
	position: fixed;
	z-index: 10;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(#000, 0.8);
	transition: opacity 0.5s ease;
	opacity: 0;

	&.fade-in {
		opacity: 1;
	}
}

.modal {
	position: absolute;
	width: calc(100% - 2.5rem);
	height: calc(100% - 2.5rem);
	top: rem(20px);
	left: 50%;
	transform: translate(-50%, 0);
	background: #fff;
	opacity: 0;
	transition: opacity 0.5s ease;

	@include bp-min(l) {
		width: 100%;
		height: initial;
		max-width: rem(912px);
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.modal__content {
		height: 100%;
		max-height: 100vh;
		padding: rem(20px);
		overflow-y: auto;
		overflow-x: hidden;

		@include bp-min(l) {
			padding: rem(24px) rem(60px);
		}
	}

	.modal__prev,
	.modal__next,
	.modal__close-button {
		position: absolute;
		padding: 0;
		border: none;
		text-indent: -999999px;
		overflow: hidden;
		cursor: pointer;
		background: $color-primary;
		color: #fff;

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-indent: 0;
		}
	}

	.modal__close-button {
		width: rem(50px);
		height: rem(50px);
		top: 0;
		right: 0;
		transition: transform 0.3s ease;

		&:before {
			//@include icon($icon-close);
			content: 'x';
			font-size: rem(18px);
			font-weight: bold;
		}

		&:hover {
			transform: scale(1.1);
		}
	}

	/*.modal__prev,
	.modal__next {
		width: 50%;
		height: rem(48px);
		bottom: 0;
		background: #fff;
		color: $color-primary;
		transition: transform 0.3s ease-in-out;

		&:before {
			font-size: rem(17px);
		}

		&:hover {
			transform: scale(1.1);
		}

		@include bp-min(l) {
			width: rem(48px);
			border-radius: 50%;
			top: 50%;
			bottom: auto;
		}
	}
		.modal__prev {
		left: 0;

		@include create-breakpoint(lm) {
			left: rem(-78px);
		}

		&:before {
			@include icon($icon-slide-left);
			margin-right: rem(2px);
		}
	}

	.modal__next {
		right: 0;

		@include create-breakpoint(lm) {
			right: rem(-78px);
		}

		&:before {
			@include icon($icon-slide-right);
			margin-left: rem(2px);
		}
	}*/

	&.fade-in {
		opacity: 1;
	}
}
﻿.swiper-content {
	padding: rem(70px) rem(40px);
	background: $color-primary;
	color: #fff;
	box-sizing: border-box;

	@include bp-min(m) {
		float: right;
		width: rem(520px);
	}
}

.swiper-slide {
	display: flex;
	flex-wrap: wrap;

	.highlighted-slider & {
		@include bp-min(m) {
			flex-direction: row-reverse;
			height: 100%;
		}
	}

	
}

.highlighted-slider {
	.swiper-wrapper {
		.swiper-slide {
			height: auto;
		}
	}
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {	
	max-width: rem(520px);
	padding: 0 rem(40px);
	right: 0;
	bottom: rem(26px);
	left: auto;
	text-align: left;
	display: none;

	@include bp-min(m) {
		display: block;
	}

	.swiper-pagination-bullet {
		width: rem(12px);
		height: rem(12px);
		background: #fff;
		opacity: 0.5;
		margin: 0 rem(6px);

		&.swiper-pagination-bullet-active {
			background: #fff;
			opacity: 1;
		}
	}
}

.swiper-container-horizontal {
	overflow: hidden;
}

.swiper-container-wrapper {
	position: relative;
}

.swiper-navigation {
	position: absolute;
	width: 100%;
	max-width: rem(1614px);
	margin: rem(30px) 0 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.swiper-button-prev,
.swiper-button-next {
	background: none;
	text-decoration: none !important;
	font-size: rem(30px);

	.is-small & {
		width: rem(50px);
		height: rem(50px);
		background: $color-primary;
		color: #fff;

		&:before {
			position: absolute;
			color: #fff;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: rem(16px);
			transition: transform 0.3s ease-in-out;
		}

		&:hover {

			&:before {
				color: #fff;
			}
		}
	}

	&:before {
		@include icon($icon-arrow-left);
		color: $color-primary;
		transition: color 250ms ease-in-out;
	}

	&.swiper-button-disabled {
		opacity: 0;
	}

	&:hover {
		&:before {
			transform: translate(-50%, -50%);
		}
	}
}

.swiper-button-prev {

	&:before {
		@include icon($icon-arrow-left);
	}

	.is-small & {
		position: absolute;
		top: auto;
		right: auto;
		bottom: 0;
		left: 0;

		&:hover {

			&:before {
				color: #fff;
			}
		}

		&:hover {

			&:before {
				transform: translate(-70%, -50%);
			}
		}
	}
}

.swiper-button-next {

	&:before {
		@include icon($icon-arrow-right);
	}

	.is-small & {
		position: absolute;
		top: auto;
		right: auto;
		bottom: 0;
		left: rem(50px);

		&:hover {

			&:before {
				transform: translate(-30%, -50%);
			}
		}
	}
}

// Thumbs
.thumbs {
	padding: 0 rem(40px);
	overflow: hidden;
	position: relative;

	.swiper-wrapper {
		/*justify-content: center;*/
	}

	.swiper-button-prev,
	.swiper-button-next {
		width: auto;
		margin-top: 0;
		transform: translateY(-50%);

		&:before {
			font-size: rem(16px);
		}
	}

	.swiper-button-prev {
		left: 0;
	}

	.swiper-button-next {
		right: 0;
	}
}

.swiper-slide.thumb {
	display: inline-block;
	cursor: pointer;
	opacity: 0.5;
	transition: opacity 250ms ease-in-out;

	&.swiper-slide-thumb-active {
		opacity: 1;
	}
}
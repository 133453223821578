﻿$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: $color-primary;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.3s;
$hamburger-hover-transition-timing-function: ease-in-out;

@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
	display: block;
	z-index: 1001;
	position: fixed;
	width: rem(60px);
	height: rem(60px);
	padding: 0 10px;
	margin: 0 0 0 rem(-70px);
	border: 1px solid rgba(#000, 0.1);
	right: rem(20px);
	top: rem(8px);
	cursor: pointer;
	background: $color-primary;
	color: #fff;
	opacity: 1;
	transition: all 0.3s ease-in-out;
	font-weight: 400;

	@include bp-min(m) {
		display: none;
	}

	&.is-active {
		display: block !important;
	}

	.hamburger-box {
		top: 4px;
	}

	.hamburger-inner,
	.hamburger-inner:before,
	.hamburger-inner:after {
		width: rem(32px);
		background: #fff;
		transition: all 0.3s ease-in-out;
	}

	&.hamburger--spin.is-active .hamburger-inner::after {
		transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s ease-in-out;
	}

	&:hover {
		background: mix(#000, $color-primary, 20%);

		.hamburger-inner,
		.hamburger-inner:before,
		.hamburger-inner:after {
			background: #fff;
		}
	}
}
﻿.logo {
	span {
		z-index: 101;
		display: block;
		width: rem(182px);
		height: rem(121px);
		text-indent: -9999px;
		background: url(../../images/logo-green-talent.svg) no-repeat center;
		position: relative;
		z-index: 10;
	}

	&:after {
		position: absolute;
		content: "";
		width: rem(182px);
		height: rem(58px);
		bottom: rem(-58px);
		bottom: 0;
		left: 0;
		background: url(../../images/curved-background.svg) no-repeat left top;
		background-size: 100% auto;
	}
}

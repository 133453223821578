﻿.faq-item {
	button {
		position: relative;
		background: #fff;
		width: 100%;
		padding: rem(29px) rem(82px) rem(30px) rem(42px);
		border: none;
		font-size: rem(18px);
		text-align: left;
		font-weight: bold;

		section:nth-of-type(odd) & {
			background: $color-contrast;
		}

		&:after {
			position: absolute;
			content: '›';
			transform: rotate(90deg);
			color: $color-primary;
			font-family: $base-font-title;
			font-size: rem(34px);
			font-weight: normal;
			top: rem(30px);
			right: rem(40px);
			transition: all 250ms ease-in-out;
		}

		&[aria-expanded="true"]:after {
			transform: rotate(270deg);
		}
	}

	> div {
		background: #fff;

		section:nth-of-type(odd) & {
			background: $color-contrast;
		}
	}

	.text {
		padding: 0 rem(40px) rem(21px);

		p:first-of-type {
			margin-top: 0;
		}
	}
}

.accordion {
	border: none;
	margin: 0 0 rem(10px);

	&:last-of-type {
		margin-bottom: rem(30px);
	}
}
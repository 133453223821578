﻿@font-face {
	font-family: 'Museo';
	src: url('../../../fonts/Museo-300.woff2') format('woff2'), url('../../../fonts/Museo-300.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Museo';
	src: url('../../../fonts/Museo-700.woff2') format('woff2'), url('../../../fonts/Museo-700.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: url('../../../fonts/OpenSans-Regular.woff2') format('woff2'), url('../../../fonts/OpenSans-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: url('../../../fonts/OpenSans-Italic.woff2') format('woff2'), url('../../../fonts/OpenSans-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'OpenSans';
	src: url('../../../fonts/OpenSans-SemiBold.woff2') format('woff2'), url('../../../fonts/OpenSans-SemiBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@mixin font-16 {
	font-family: $base-font-family;
	font-size: rem(18px);
	line-height: rem(30px);

	@media only screen and (min-width: 432px) {
		font-size: rem(17px);
	}

	@media only screen and (min-width: 600px) {
		font-size: rem(16px);
	}
}

@mixin font-12 {
	font-family: $base-font-family;
	font-size: rem(12px);
	line-height: rem(24px);
}

@mixin font-title-18 {
	font-family: $base-font-title;
	font-size: rem(18px);
	line-height: rem(26px);
}

@mixin font-title-20 {
	font-family: $base-font-title;
	font-size: rem(20px);
	line-height: rem(32px);
}

@mixin font-title-24 {
	font-family: $base-font-title;
	font-size: rem(24px);
	line-height: rem(36px);
}

@mixin font-title-32 {
	font-family: $base-font-title;
	font-size: rem(32px);
	line-height: rem(40px);
}

@mixin font-title-40 {
	font-family: $base-font-title;
	font-size: rem(40px);
	line-height: rem(36px);
}
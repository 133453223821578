﻿.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255, .7);
	z-index: 1001;
	padding-top: 7.5rem;
	overflow-y: auto;
	transition: .2s ease-in-out;

	&.invisible {
		opacity: 0;
		pointer-events: none;
		transition: .2s ease-in-out;
	}

	.login-survey {
		position: relative;
		max-width: 95vw;
		padding: 3rem 1.5rem;
		width: auto;
		background-color: $color-primary-dark;
		color: #fff;
		margin: 0 auto 3rem;
		overflow: hidden;

		@include bp(l) {
			max-width: 48.5rem;
			padding: 7rem 6rem 6rem;
		}

		.close-btn {
			position: absolute;
			content: '';
			width: 2rem;
			height: 2rem;
			background: #fff;
			top: 1rem;
			right: 1rem;
			cursor: pointer;
			transition: all .15s ease;

			&:after {
				@include icon($icon-close);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				color: $color-banana;
				font-size: .8rem;
				font-weight: 600;
				transition: all .15s ease;
			}

			&:hover {
				background: $color-banana;
				transition: all .15s ease;

				&:after {
					color: #fff;
					transition: all .15s ease;
				}
			}
		}

		.survey-intro {
			@include font-title-24;
			font-weight: normal;
			margin: 0 0 rem(21px);
			margin-bottom: 2rem;

			@include bp-max(l) {
				font-size: 1.25rem;
				line-height: 2rem;
			}
		}

		input {
			border: 0.05rem solid #000;
		}

		.checkboxlist {
			display: block;
			margin-bottom: 2rem;

			.survey-option {
				display: flex;
				width: 100%;
				margin-bottom: 1rem;

				input[type=checkbox] {
					position: relative;
					-moz-appearance: none;
					-webkit-appearance: none;
					-o-appearance: none;
					outline: none !important;
					background-color: #fff;
					border: 1px solid #000;
					margin: 0 1rem 0 0;
					padding: unset !important;

					&:checked {
						&:before {
							position: absolute;
							content: '';
							background: $color-banana;
							width: 65%;
							height: 65%;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}

				label {
					@include font-16;
					font-weight: bold !important;
					display: block;
					width: 100%;
					padding: unset;
					line-height: 1rem !important;
				}
			}
		}

		.buttons {
			margin: unset;
			display: flex;
			justify-content: end;

			.button {
				margin: unset;
			}
		}
	}
}

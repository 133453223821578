﻿.filter {
	display: flex;
	flex-wrap: wrap;
	margin: -10px -10px 40px;

	input[type=radio] {
		display: none;

		&:checked + label {
			background: darken($color-primary, 10%);
		}
	}

	.button {
		width: auto;
		margin: 10px;
	}

	button[type=submit] {

		.js & {
			display: none;
		}
	}
}

﻿.usp-item {
	box-sizing: border-box;
	background: $color-contrast;
	height: 100%;
	padding: rem(100px) rem(40px) rem(40px);
	border: 1px solid #fff;
	position: relative;

	.-alt-background & {
		background: #fff;
		border-color: $color-contrast;
	}

	&:before {
		color: $color-primary;
		font-size: rem(48px);
		position: absolute;
		top: rem(40px);
		left: rem(40px);
	}

	&.icon-:before { content: $icon-green-talent; }
	&.icon-car:before { font-size: rem(60px); }

	a {
		display: block;
		width: 100%;
		height: 100%;
		padding: rem(100px) rem(40px) rem(40px);
		position: absolute;
		top: 0;
		left: 0;
		transition: color 250ms ease-in-out;
		font-size: 0;
		text-indent: -9999px;

		&:hover {
			background: rgba($color-contrast, 0.3);
		}
	}
}
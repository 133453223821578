﻿.-sticky {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1000;
	width: 50%;
	margin: 0;

	@include bp-min(m) {
		width: auto;
		top: 50%;
		right: 0;
		bottom: auto;
		left: auto;
		width: auto;
		transform: rotate(270deg) translateX(50%);
		transform-origin: right bottom;
		box-shadow: 0 0 rem(30px) rgba(0,0,0,0.2);
	}

	.button {
		background: $color-banana;
		color: #fff;
		width: 100%;
		height: rem(60px);
		padding: 0;
		position: relative;

		@include bp-min(m) {
			background: #fff !important;
			color: $color-banana;
			text-indent: 0;
			width: auto;
			height: auto;
			padding: rem(17px) rem(80px) rem(17px) rem(30px);
		}

		.icon {
			color: #fff;
			text-indent: 0;
			display: inline-block;
			width: rem(60px);
			height: rem(60px);
			position: relative;
			transition: all 0.3s ease-in-out;

			&:before {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			@include bp-min(m) {
				background: $color-banana;
				display: inline-block;
				position: absolute;
				top: 0;
				right: rem(-10px);
				transform: rotate(90deg);
				border-radius: 0;
			}
		}

		&:hover,
		&:focus {
			.icon {
				background: #fff;
				color: $color-banana;
			}
		}

		&.-alt {
			&:hover,
			&:focus {
				color: $color-banana;
			}
		}
	}
}

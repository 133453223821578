﻿.employee {
	position: relative;

	figure {
		position: relative;
		overflow: hidden;
		margin: 0;

		&:before {
			position: relative;
			display: block;
			content: "";
			padding-top: (100% / 480 * 418);
		}

		&:after {
			overflow: hidden;
			height: 0;
			padding: 0 0 (100% / 390 * 50);
			position: absolute;
			content: '';
			z-index: 1;
			width: calc(100% + 4px);
			bottom: -2px;
			right: -2px;
			left: -2px;
			background: url(../../images/small-gray-mask.svg);
			background-size: auto;
			background-size: cover;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
		}

		&.image-placeholder {
			background: $color-primary url(../../images/slogan.svg) no-repeat center center;
			background-size: 50% auto;
		}
	}

	.employee-details {
		background: $color-contrast;
		padding: rem(40px 20px);
		margin-top: auto;
		position: relative;
		text-align: center;

		h2 {
			margin-top: 0;

			+ .employee-function {
				display: block;
				margin-top: rem(-25px);
				margin-bottom: rem(25px);
			}
		}
	}
}

.employees {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	> div {
		margin: 0 0 rem(50px);
	}
}
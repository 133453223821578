﻿/*------------------------------------*\
	#Responsive nav
\*------------------------------------*/

.responsive-nav {
	position: fixed;
	z-index: 1000;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	transition: all 0.3s ease-in-out;
	visibility: hidden;
	opacity: 0;
	background: $color-primary;

	@include bp-min(l) {
		background: none;
		position: absolute;
		width: auto;
		height: auto;
		visibility: visible;
		opacity: 1;
		padding: 0;
	}

	&.-open {
		visibility: visible;
		opacity: 1;
	}

	&__inner {
		height: 100%;
		overflow-x: hidden;
		overflow-y: visible;

		@include bp-min(l) {
			overflow: initial;
			height: auto;
		}
	}
}
﻿.dropdown {
	background: #fff;
	color: $color-primary;
	font-family: $base-font-title;
	font-size: rem(18px);
	position: relative;

	dt {
		padding: rem(15px) rem(80px) rem(15px) rem(20px);
		position: relative;
		cursor: pointer;

		&:before {
			width: rem(60px);
			height: rem(60px);
			position: absolute;
			top: 0;
			right: 0;
			content: '';
			background: $color-contrast;
		}

		&:after {
			@include icon($icon-arrow-bottom);
			font-size: rem(12px);
			position: absolute;
			top: 50%;
			right: rem(24px);
			transform: translateY(-50%);
			transition: all 250ms ease-in-out 0s;
		}

		&[aria-expanded="true"]:after {
			transform: rotate(180deg);
			transform-origin: center;
			margin-top: rem(-4px);
		}
	}

	dd {
		background: #fff;
		position: absolute;
		z-index: 10;
		width: 100%;
		min-height: 0;
		margin: 0;
		visibility: hidden;

		ul {
			border-width: 0 1px 1px;
			border-style: solid;
			border-color: $color-contrast;

			li {
				a {
					display: block;
					padding: rem(15px) rem(20px);
					border-top: 1px solid $color-contrast;
					font-weight: normal;

					&:hover {
						background: $color-contrast;
					}
				}
			}
		}
	}

	@mixin open {
		z-index: 2;

		dt,
		dt:focus {
			&::before {
				// Used for dropdown list
				display: block;
			}

			&::after {
				content: $icon-arrow-up;
			}
		}

		dd {
			visibility: visible;
		}
	}

	&.clicked.open {
		@include open;
	}

	&:not(.clicked):focus-within {
		@include open;
	}
}

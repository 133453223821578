﻿.navigation {
	@include bp-min(l) {
		padding-right: rem(120px);
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		@include bp-min(l) {
			float: left;
			padding: 0;
			margin: 0 -1.5rem 0 0;
		}

		li {
			width: 100%;

			@include bp-min(l) {
				float: left;
				width: auto;
			}

			&.sub > a {
				padding-right: rem(24px);
				position: relative;

				&:after {
					@include icon($icon-arrow-bottom);
					font-size: rem(10px);
					position: absolute;
					top: rem(34px);
					right: 0;
				}
			}

			a {
				color: #fff;
				font-weight: bold;
				font-size: rem(24px);
				font-family: $base-font-title;
				display: block;
				padding: rem(20px);

				@include bp-min(l) {
					color: $color-primary;
					font-size: rem(18px);
					padding: rem(25px) rem(20px);
				}
			}

			&:hover > a,
			&.active > a {
				background: $color-primary-dark;
				transition: all 250ms ease-in-out;

				@include bp-min(l) {
					background: none;
					color: $color-primary-dark;
				}
			}
			// Second level
			ul {
				@include bp-min(l) {
					position: absolute;
					display: none;
					background: #fff;
					box-shadow: 0 rem(15px) rem(15px) rgba(0,0,0,0.2);
				}

				li {
					float: left;
					clear: left;
				}
			}

			&:hover {
				> ul {
					display: block;
				}
			}
		}
	}
}

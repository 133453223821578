﻿.links {
	@extend .no-bullets;
	margin: 0;

	&.is-big {
	}
}

.link {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	&.-telephone,
	&.-mail {
		position: relative;
		padding: 0 0 0 rem(46px);

		&:before {
			position: absolute;
			left: 0;
			font-size: rem(24px);
		}
	}

	&.-telephone {

		&:before {
			@include icon($icon-telephone);
			top: rem(-2px);
		}
	}

	&.-mail {

		&:before {
			@include icon($icon-mail);
			top: rem(-1px);
		}
	}

	&.is-big {
		position: relative;
		display: inline-block;
		font-size: rem(18px);
		font-weight: bold;
		padding: rem(12px) rem(14px) rem(12px) rem(70px);
		cursor: pointer;

		&:after {
			@include icon($icon-arrow-bold-right);
			position: absolute;
			top: rem(23px);
			right: 0;
			font-size: rem(10px);
			transition: right 0.3s ease-in-out;
		}

		&.is-accommodation,
		&.is-transport {

			&:before {
				position: absolute;
				top: 50%;
				left: 0;
				color: $color-primary;
				transform: translateY(-50%);
			}
		}

		&.is-accommodation {

			&:before {
				@include icon($icon-home);
				font-size: rem(45px);
			}
		}

		&.is-transport {
			padding-left: rem(82px);

			&:before {
				@include icon($icon-car);
				font-size: rem(62px);
			}
		}

		&:hover {
			text-decoration: none;

			&:after {
				right: rem(-2px);
			}
		}
	}

	&.-back-to-overview {
		@include font-title-18;
		position: relative;
		font-weight: bold;
		color: $color-primary;
		text-decoration: none;
		padding: 0 0 0 rem(16px);

		&:before {
			@include icon($icon-arrow-bold-left);
			position: absolute;
			top: rem(8px);
			left: 0;
			font-size: rem(10px);
			transition: left 0.3s ease-in-out;
		}

		&:hover {

			&:before {
				left: rem(-2px);
			}
		}
	}
}

.link-listing {
	@extend .no-bullets;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	margin-bottom: 1.875rem;

	li {
		padding: 1rem 1.5rem;
		padding: 1rem 3rem 1rem 1.5rem;
		border-top: 1px solid rgba(0,0,0,0.1);
		position: relative;

		@include bp-min(m) {
			padding-right: 2.5rem;
			padding-left: 2.5rem;
		}

		&:nth-of-type(even) {
			background: $color-contrast;
		}

		a {
			width: 100%;
			height: 100%;
			padding: 1rem 1.5rem;
			position: absolute;
			left: 0;
			top: 0;
			text-decoration: none;

			@include bp-min(m) {
				padding-right: 2.5rem;
				padding-left: 2.5rem;
			}

			&:after {
				@include icon($icon-arrow-right);
				position: absolute;
				top: 50%;
				right: 1.5rem;
				transform: translateY(-50%);
				color: $color-primary;
				transition: right 250ms ease-in-out;

				@include bp-min(m) {
					right: 2.5rem;
				}
			}

			&:hover:after {
				right: 1rem;

				@include bp-min(m) {
					right: 2rem;
				}
			}
		}

		strong {
			color: $color-primary;
			font-size: 1.125rem;
		}
	}
}

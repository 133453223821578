﻿.whatsapp {
	position: fixed;
	bottom: 0;
	left: 50%;
	z-index: 1000;
	width: 50%;
	margin: 0;

	@include bp-min(m) {
		width: auto;
		bottom: rem(24px);
		right: rem(24px);
		left: auto;
		box-shadow: 0 0 rem(30px) rgba(0,0,0,0.2);
		border-radius: 100%;
	}

	.button {
		width: 100%;
		height: rem(60px);

		@include bp-min(m) {
			width: rem(60px);
			border-radius: 100%;
			font-size: 0;
			position: relative;

			.icon {
				position: relative;
				text-indent: 0;
				display: block;

				&:before {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: rem(32px);
					padding-top: rem(24px);
				}
			}
		}
	}
}

﻿label {
	@include font-16;
	margin-bottom: rem(20px);
	padding: rem(10px) 0;
	font-weight: bold;

	@include bp-min(m) {
		float: left;
		width: rem(210px);
		margin: 0;
	}
}

select,
textarea,
input,
.readonly {
	@include font-16;
	outline: none;
	margin-bottom: rem(4px);
	text-transform: none;
	color: $color-primary-dark;
	font-weight: bold;
	border-radius: 0;
	border: none;

	&:invalid {
		box-shadow: none;
	}

	&:focus {

		&:invalid {
			border-bottom-color: $color-primary;
		}
	}

	@include placeholder {
		color: $color-primary;
	}

	@include bp-min(m) {
		float: left;
		width: calc(100% - 13.125rem);
	}
}

textarea {
	min-height: rem(330px);

	.form-contactnl & {
		min-height: rem(190px);
	}
}

.input-group-addon {
	padding-left: 1rem;
}

.form-actions {
	padding: 0;
}

.validation-summary-errors ul {
	@extend .no-bullets;
}

.help-text {
	background-color: $alert-background-color;
	padding: 0.5rem 1rem;
}

#fieldset-e1afdf62-b46e-40ee-8793-fe69120e747a {
	display: none;
}

.umbraco-forms-field {
	display: inline-block;
	width: 100%;
	font-size: 0;

	input[type=checkbox] {
		margin-top: 0.875rem;
		margin-right: 1rem;
	}
}

.checkbox {
	display: flex;
	width: 100%;

	label {
		min-width: 85%;

		p {
			margin: unset;
		}
	}
}
﻿.legal {
	@include font-12;
	position: relative;
	padding: 0;
	margin: 0;
	overflow: hidden;

	&__list {
		overflow: hidden;
		list-style: none;
		margin: 0;
		padding: rem(9px) 0 rem(7px);

		@include bp-min(m) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	li {
		float: left;
		padding-top: rem(4px);
		padding-bottom: rem(4px);
		margin-right: rem(20px);
		text-transform: none;

		&.-developer {
			margin-left: auto;
			margin-right: 0;
		}
	}

	a {
		text-decoration: none;
		color: inherit;

		&:hover {
			text-decoration: underline;
		}
	}
}

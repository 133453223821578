﻿.overview {
	overflow: hidden;
	width: 100%;
}

.overview-item {
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
	background: $color-contrast;
	margin: 0 0 rem(50px);

	&.is-hidden {
		display: none;
	}

	.overview-item__image {
		@include responsive-container-psudo(9, 4);
		margin: 0;
		width: 100%;
		background: rgba($color-primary, 0.5);

		@include bp-min(s) {
			width: 50%;
		}

		@include bp-min(l) {
			width: 15%;
		}

		img {
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	.overview-item__text {
		width: 100%;
		padding: 15px 20px;
		text-decoration: none;

		@include bp-min(s) {
			width: 50%;
		}

		@include bp-min(l) {
			width: 30%;

			&.-large {
				width: 55%;

				+ .overview-item__short-info {
					@include bp-min(l) {
						width: 15%;

						> div {
							width: 100%;
						}
					}
				}
			}
		}
	}

	.overview-item__short-info {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		padding: 15px 20px;
		text-decoration: none;

		@include bp-min(l) {
			width: 40%;
		}

		> div {
			width: 100%;
			text-align: center;

			@include bp-min(xs) {
				width: (100% / 3);
			}
		}
	}

	.overview-item__buttons {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		text-decoration: none;

		@include bp-min(l) {
			width: 15%;
		}

		.button {
			display: flex;
			width: 100%;
			text-align: center;
			justify-content: center;
			align-items: center;

			@include bp-min(s) {
				width: 50%;
			}

			@include bp-min(l) {
				width: 100%;
			}
		}
	}

	.overview-item__title {
		@include font-title-18;
		color: $color-primary;
		margin: 0;
	}

	.overview-item__location,
	.overview-item__hourly-wage,
	.overview-item__period,
	.overview-item__date {
		position: relative;
		padding: 40px 0 0 0;

		i {
			position: absolute;
			top: 10px;
			left: 50%;
			transform: translateX(-50%);
			font-size: 20px;
		}
	}
}